import React from "react";
import Logo from "../assets/img/Right SideText.svg";
import { AiOutlineTwitter, AiFillInstagram } from "react-icons/ai";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

const Footer = () => {
  return (
    <footer className="bg-dark py-3 mt-5">
      <div className="container mt-3 text-white">
        <h3 className="text-center display-5 mb-4 pt-5">
          Subscribe for updates
        </h3>
        <p className="text-center pt-3 pb-5">
          sign up for our monthly newsletter, where we'll keep <br />
          you up-to-date with the letest product updates.
        </p>
        <div className="d-flex mt-3 mx-auto pb-5" style={{ maxWidth: "750px" }}>
          <input
            type="email"
            className="form-control rounded-0"
            placeholder="abc@xyz.com"
          />
          <button className="btn btn-info rounded-0">Subscribe</button>
        </div>
        <hr />
        <div className="row mt-5 pt-3">
          <div className="col-md-4">
            <div>
              <img src={Logo} height="60px" alt="logo" />
              <div
                className="d-flex flex-wrap mt-3 justify-content-between"
                style={{ maxWidth: "240px" }}
              >
                <a href="!#" className="btn btn-outline-light">
                  <AiOutlineTwitter />
                </a>
                <a href="!#" className="btn btn-outline-light">
                  <FaFacebookF />
                </a>
                <a href="!#" className="btn btn-outline-light">
                  <FaLinkedinIn />
                </a>
                <a href="!#" className="btn btn-outline-light">
                  <AiFillInstagram />
                </a>
                <a href="!#" className="btn btn-outline-light">
                  <IoLogoWhatsapp />
                </a>
              </div>
              <div className="mt-4 pt-5">
                &copy; Coinfidence Corporation Inc.
              </div>
              <div> All rights reserved</div>
            </div>
          </div>
          <div className="col-md-4">
            <h3>Products</h3>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <img src={Logo} height="60px" alt="logo" />
              <div className="ms-2">
                <h4>Comming soon</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
