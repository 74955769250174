import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Aboutus from "./pages/Aboutus";
import Contactus from "./pages/Contactus";
import Home from "./pages/HomeNew";
import Portfolio from "./pages/Portfolio";
const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/contactus">
          <Contactus />
        </Route>
        <Route path="/aboutus">
          <Aboutus />
        </Route>
        <Route path="/portfolio">
          <Portfolio />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
