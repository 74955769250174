import React from "react";
import Particles from "react-particles-js";
import { Link } from "react-router-dom";
import particlesConfig from "../assets/json/particlesConfig.json";
import logo from "../assets/img/Right SideText.svg";
const Header = () => {
  return (
    <header className="landingPageHeader">
      <Particles params={particlesConfig} className="homeParticals" />
      <div className="content text-center">
        <div>
          <img src={logo} width="100%" alt="logo" />
        </div>
        <ul className="menuList">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/aboutus">About Us</Link>
          </li>
          <li>
            <Link to="/contactus">Contact Us</Link>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
