import { useState } from "react";
import React from "react";
import { app } from "../config/firebase/base";
import Swal from "sweetalert2";
const db = app.firestore();
const Indvidual = () => {
  const [formData, setformData] = useState({
    title: "",
    surname: "",
    firstName: "",
    dob: "",
    emailAddress: "",
    address: "",
    passport: "",
    utilityBill: "",
  });
  const submitForm = async (e) => {
    e.preventDefault();
    if (
      formData.title === "" ||
      formData.surname === "" ||
      formData.firstName === "" ||
      formData.dob === "" ||
      formData.address === "" ||
      formData.passport === "" ||
      formData.utilityBill === "" ||
      formData.emailAddress === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pleas fill whole form",
      });
      return null;
    } else {
      await db
        .collection("individual")
        .doc(formData.emailAddress)
        .set({ ...formData });
      e.target.reset();
      Swal.fire({
        icon: "success",
        title: "Data save successfull",
        showConfirmButton: false,
        timer: 1500,
      });
      setformData({
        title: "",
        surname: "",
        firstName: "",
        dob: "",
        emailAddress: "",
        address: "",
        passport: "",
        utilityBill: "",
      });
    }
  };
  const uploadfiles = async (e, name) => {
    try {
      const file = e.target.files[0];
      const storageRef = app.storage().ref();
      const fileRef = storageRef.child(file.name);
      await fileRef.put(file);
      const setFileUrl = await fileRef.getDownloadURL();
      const data = {};
      data[name] = setFileUrl;
      setformData({ ...formData, ...data });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={submitForm}>
      <div className="row">
        <div className="col-4">
          <div className="form-group mb-3">
            <label htmlFor="title">Title</label>
            <select
              name="title"
              required
              value={formData.title}
              className="form-control"
              onChange={(e) =>
                setformData({ ...formData, title: e.target.value })
              }
            >
              <option value="">--select--</option>
              <option>Mrs.</option>
              <option>Miss.</option>
              <option>Ms.</option>
              <option>Mx.</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label htmlFor="surname">Surname</label>
            <input
              type="text"
              name="surname"
              value={formData.surname}
              required
              className="form-control"
              onChange={(e) =>
                setformData({ ...formData, surname: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              required
              className="form-control"
              onChange={(e) =>
                setformData({ ...formData, firstName: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <div className="form-group mb-3">
        <label htmlFor="dob">DOB</label>
        <input
          type="date"
          name="dob"
          value={formData.dob}
          required
          className="form-control"
          onChange={(e) => setformData({ ...formData, dob: e.target.value })}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="emailAddress">Email Address</label>
        <input
          type="email"
          name="emailAddress"
          value={formData.emailAddress}
          required
          className="form-control"
          onChange={(e) =>
            setformData({ ...formData, emailAddress: e.target.value })
          }
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="address">Address(for past 3 years)</label>
        <input
          type="email"
          name="address"
          value={formData.address}
          required
          className="form-control"
          onChange={(e) =>
            setformData({ ...formData, address: e.target.value })
          }
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="address">Passport or driving lisence</label>
        <input
          type="file"
          className="form-control"
          name="address"
          required
          onChange={(e) => uploadfiles(e, "passport")}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="address">Utility Bill less then three month old</label>
        <input
          type="file"
          className="form-control"
          name="address"
          required
          onChange={(e) => uploadfiles(e, "utilityBill")}
        />
      </div>
      <button className="btn btn-light" type="submit">
        submit
      </button>
    </form>
  );
};

export default Indvidual;
