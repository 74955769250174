import React, { useEffect, useState } from "react";
import { AiOutlineArrowUp } from "react-icons/ai";
const GotTop = () => {
  const [scrollVal, setScrollVal] = useState(0);
  useEffect(() => {
    window.onscroll = () => {
      setScrollVal(window.pageYOffset);
    };
  });
  return (
    scrollVal > window.innerHeight / 2 && (
      <button
        className="gotopBtn btn btn-primary"
        onClick={() => window.scrollTo(0, 0)}
      >
        <AiOutlineArrowUp />
      </button>
    )
  );
};

export default GotTop;
