import React from "react";
import NavBar from "../components/NavBar2";

const Aboutus = () => {
  return (
    <main className="mainHome">
      <NavBar />
      <div className="container text-light">
        <h3 className="">About us</h3>
        <p className="">
          Confidence is a global leader in the digital investment marketplace.
          Our assets and services provide exposure to the infrastructure of the
          future within the blockchain space.
        </p>
      </div>
    </main>
  );
};

export default Aboutus;
