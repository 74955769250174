import React from "react";
import Header from "../components/Header2";

const Home = () => {
  return (
    <main className="mainHome">
      <Header />
    </main>
  );
};

export default Home;
