import firebase from "firebase";
import "firebase/storage";
export const app = firebase.initializeApp({
  apiKey: "AIzaSyBiv5xUiFT7jTsPyPpuXE3MJemJwHlvHBQ",
  authDomain: "coninfidence.firebaseapp.com",
  projectId: "coninfidence",
  storageBucket: "coninfidence.appspot.com",
  messagingSenderId: "1013919527834",
  appId: "1:1013919527834:web:decaf61452caf1251a4023",
  measurementId: "G-69MD9FZYYZ",
});
// Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
//   firebase.analytics();
