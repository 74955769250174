import React from "react";
import NavBar from "../components/NavBar2";
import { IoLogoWhatsapp } from "react-icons/io5";
const Contactus = () => {
  return (
    <main className="mainHome">
      <NavBar />
      <div className="container text-light">
        <h3 className="">Contact us</h3>

        <div className="row">
          <div className="col-md-7">
            <div>
              <div class="mb-3">
                <label for="userName" class="form-label">
                  Your Name (required)
                </label>
                <input type="text" class="form-control" id="userName" />
              </div>
              <div class="mb-3">
                <label for="userEmail" class="form-label">
                  Your Email (required)
                </label>
                <input type="email" class="form-control" id="userEmail" />
              </div>
              <div class="mb-3">
                <label for="subject" class="form-label">
                  Subject
                </label>
                <input type="text" class="form-control" id="subject" />
              </div>
              <div class="mb-3">
                <label for="subject" class="form-label">
                  Subject
                </label>
                <textarea class="form-control" id="subject" />
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="bg-light rounded text-dark px-2 py-4">
              <h5>Mailing and Correspondence :</h5>
              <h6>United Kingdom:</h6>
              <p>address</p>
            </div>
          </div>
        </div>
      </div>
      <a
        className="btn btn-success whatsAppBtn"
        href="https://api.whatsapp.com/send/?phone="
      >
        <IoLogoWhatsapp />
      </a>
    </main>
  );
};

export default Contactus;
