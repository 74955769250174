import React from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import Indvidual from "../components/Indvidual";
import Corporates from "../components/Corporates";
import ScrollToTop from "../components/ScrollToTop";
import { useState } from "react";
import GotTop from "../components/GotTop";
import spot from "../assets/img/934.svg";
import corAlt from "../assets/img/791.png";
import incomePortfolio from "../assets/img/401.png";
import COMPLETEPortfolio from "../assets/img/442.svg";
import secourtyImg from "../assets/img/345.png";
import HeaderImg from "../assets/img/rm373batch16-banner-09.png";
import EtosImg from "../assets/img/505.svg";

const Portfolio = () => {
  const [state, setState] = useState("individual");
  const formReturn = () => {
    switch (state) {
      case "individual":
        return <Indvidual />;
      case "corporates":
        return <Corporates />;
      default:
        return <Indvidual />;
    }
  };
  return (
    <main className="mainHome">
      <ScrollToTop />
      <GotTop />
      <NavBar />
      <nav className="secondNav">
        <a href="#overview" className="active">
          overview
        </a>
        <a href="#strategies">strategies</a>
        <a href="#security">security</a>
        <a href="#register">register</a>
      </nav>
      <section>
        <div
          className="container text-white d-flex justify-content-center align-items-center"
          style={{ minHeight: "100vh" }}
        >
          <div className="row h-100 align-items-center">
            <div className="col-md-6 col-lg-5">
              <h3 className="mb-4" style={{ maxWidth: "370px" }}>
                Investment innovation at the intersection of technology
              </h3>
              <p style={{ maxWidth: "370px" }}>
                Coinfidence offers its clients the opportunity to gain access to
                the world’s fastest growing asset class through its unique
                personal advisory service.
              </p>
            </div>
            <div className="col-md-6 col-lg-7">
              <img src={HeaderImg} className="w-100" alt="HeaderImg" />
            </div>
          </div>
        </div>
      </section>
      <section
        id="overview"
        className="text-white container d-flex justify-content-center align-items-center"
        style={{ minHeight: "600px" }}
      >
        <div className="row h-100 justify-content-center align-items-center">
          <div className="col-md-7 text-center">
            <h4 className="mt-3 headerHead">Invest with coinfidence</h4>
            <p className="headerDetail">
              Coinfidence, established in Spring 2021 is a bespoke investment
              advisory firm, working with high net worth investors, family
              offices and institutions. Clients are appointed dedicated expert
              advisors to manage and monitor their portfolio whilst coordinating
              with them directly to keep them informed on market moves and
              performance. Our team includes some of the most talented asset
              managers with a network that has taken decades to establish. We
              enter this space precisely to provide access to a market that is
              fast becoming the future with an increasing amount of discerning
              private investors and institutions looking to move towards digital
              assets.
            </p>
          </div>
        </div>
      </section>
      <section className="container-fluid mt-5 text-white mb-5 pb-5">
        <h3 className="mb-5 text-center text-uppercase" id="strategies">
          Strategies
        </h3>
        <div className="row">
          <div className="col-12 order-0 order-lg-0 strategiesBox  ">
            <div className="container">
              <div className="row">
                <div className="col-md-6  order-1">
                  <img src={spot} alt="spot" className="w-100" />
                </div>
                <div className=" order-0 col-md-6">
                  <h4 className="mb-4 text-uppercase">Core Portfolio</h4>
                  <p className="description">
                    An inflation hedge capital appreciation strategy holding
                    core digital assets for the long term, advising on the most
                    advantageous times to buy and sell, in line with market
                    sentiment and natural cycles
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12  order-1 order-lg-1 strategiesBox">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <img src={corAlt} alt="corAlt" className=" w-100" />
                </div>
                <div className="col-md-6 ">
                  <h4 className="mb-4 text-uppercase">
                    Core &amp; Alt portfolio
                  </h4>
                  <p className="description">
                    A well-researched portfolio holding top 5 coins with
                    optional smaller market cap projects for aggressive capital
                    growth
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 order-3 order-lg-2  strategiesBox ">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 order-1">
                  <img
                    src={incomePortfolio}
                    alt="incomePortfolio"
                    className="w-100 "
                  />
                </div>
                <div className="col-md-6 order-0">
                  <h4 className="mb-4 text-uppercase">Income Portfolio</h4>
                  <p className="description">
                    An income generating strategy which takes advantage of
                    staking and yield farming projects with atractive APRs.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12  order-2 order-lg-3 strategiesBox  ">
            <div className="container">
              <div className="row align-items-center">
                <div className="order-0 col-md-6">
                  <img
                    src={COMPLETEPortfolio}
                    alt="COMPLETEPortfolio"
                    className=" w-100"
                  />
                </div>
                <div className=" order-1 col-md-6">
                  <h4 className="mb-4 text-uppercase">Complete Portfolio</h4>
                  <p className="description">
                    Our complete portfolio is an allocated combination of all
                    portfolio with optional long or short leveraged trades under
                    the guidance of our experienced traders, for shorter term
                    trading
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="text-white strategiesBox " id="security">
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-md-6 order-1">
                <img src={secourtyImg} alt="secourtyImg" className=" w-100" />
              </div>
              <div className=" order-0 col-md-6">
                <h4 className="mb-4 text-uppercase">Security</h4>
                <p className="description">
                  At Confidence we acknowledge that we are in the early stages
                  of the growing cryptocurrency space, security of our client’s
                  funds is of upmost importance to us and our reputation.
                  Coinfidence works with well-researched and trusted crypto
                  exchanges in which we personally hold our digital assets. Our
                  clients are given the option to store their assets offline
                  with Coinfidence Ledger* to house them under a private key and
                  cold wallet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        className="pb-5 text-white bg-dark"
        style={{ minHeight: "450px" }}
      >
        <div className="container py-5">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-7 mt-5">
              <h4 className="mb-3 text-uppercase">Ethos</h4>
              <p>
                Our view on the market is underpinned by years of experience in
                both regulated and alternative investment markets. Integrity is
                at the core of Coinfidence, we thrive on building and nurturing
                trusted relationships with our clients, we are committed to
                offering them a transparent and flexible approach with aligned
                incentives.
              </p>
            </div>
            <div className="col-md-5">
              <img src={EtosImg} alt="EthosImg" className="w-100" />
            </div>
          </div>
        </div>
      </section>
      <section className=" formSction">
        <div className="container ">
          <div className="row align-items-end">
            <div className="col-md-6 col-lg-6 col-xl-5">
              <div className="portfolioForm" id="register">
                <h3>
                  Register as <span className="text-capitalize">{state}</span>
                </h3>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="formShow"
                      id="individualRadio"
                      checked={state === "individual"}
                      onChange={() => setState("individual")}
                    />
                    <label class="form-check-label" for="individualRadio">
                      Indvidual
                    </label>
                  </div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="formShow"
                      id="corporatesRadio"
                      checked={state === "corporates"}
                      onChange={() => setState("corporates")}
                    />
                    <label class="form-check-label" for="corporatesRadio">
                      Corporates
                    </label>
                  </div>
                </div>
                {formReturn()}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Portfolio;
