import { useState } from "react";
import React from "react";
import { app } from "../config/firebase/base";
import Swal from "sweetalert2";
const db = app.firestore();
const Indvidual = () => {
  const [formData, setformData] = useState({
    email: "",
    registry: "",
    bankstatement: "",
    businessActivity: "",
    personalIdentification: "",
    groupStructure: "",
    boardResolution: "",
    certifivateIncorporation: "",
    financialAccount: "",
    DeclarationTrust: "",
    // allDoc: "",
  });
  const submitForm = async (e) => {
    e.preventDefault();
    if (
      formData.email === "" ||
      formData.registry === "" ||
      formData.bankstatement === "" ||
      formData.businessActivity === "" ||
      formData.groupStructure === "" ||
      formData.boardResolution === "" ||
      formData.certifivateIncorporation === "" ||
      formData.financialAccount === "" ||
      formData.DeclarationTrust === "" ||
      formData.personalIdentification === ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Pleas provide required data first",
      });
      return null;
    } else {
      await db
        .collection("corporates")
        .doc(formData.email)
        .set({ ...formData });
      e.target.reset();
      Swal.fire({
        icon: "success",
        title: "Data save successfull",
        showConfirmButton: false,
        timer: 1500,
      });
      setformData({
        email: "",
        registry: "",
        bankstatement: "",
        businessActivity: "",
        personalIdentification: "",
        groupStructure: "",
        boardResolution: "",
        certifivateIncorporation: "",
        financialAccount: "",
        DeclarationTrust: "",
      });
    }
  };
  const uploadfiles = async (e, name) => {
    try {
      const file = e.target.files[0];
      const storageRef = app.storage().ref();
      const fileRef = storageRef.child(file.name);
      await fileRef.put(file);
      const setFileUrl = await fileRef.getDownloadURL();
      const data = {};
      data[name] = setFileUrl;
      setformData({ ...formData, ...data });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={submitForm}>
      <div className="form-group mb-3">
        <label htmlFor="email">Email Address</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          required
          className="form-control"
          onChange={(e) => setformData({ ...formData, email: e.target.value })}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="registry">
          Up to date Registry Extract or equivalent document from the relevant
          registry confirming ALL officers and shareholders of the Company
        </label>
        <input
          type="file"
          className="form-control"
          name="registry"
          required
          onChange={(e) => uploadfiles(e, "registry")}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="bankstatement">
          A copy of the Company’s latest bank statement
        </label>
        <input
          type="file"
          className="form-control"
          name="bankstatement"
          required
          onChange={(e) => uploadfiles(e, "bankstatement")}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="businessActivity">
          Details of the company’s business activities and website url
        </label>
        <input
          type="file"
          className="form-control"
          name="businessActivity"
          required
          onChange={(e) => uploadfiles(e, "businessActivity")}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="personalIdentification">Personal identification</label>
        <input
          type="file"
          className="form-control"
          name="personalIdentification"
          required
          onChange={(e) => uploadfiles(e, "personalIdentification")}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="groupStructure">Group Structure Chart</label>
        <input
          type="file"
          className="form-control"
          name="groupStructure"
          required
          onChange={(e) => uploadfiles(e, "groupStructure")}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="boardResolution">
          A copy of the Board Resolution authorising the client company
          officer/individual to establish a relationship with Digital Asset
          Management Limited and showing who is authorised to issue instructions
        </label>
        <input
          type="file"
          className="form-control"
          name="boardResolution"
          required
          onChange={(e) => uploadfiles(e, "boardResolution")}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="certifivateIncorporation">
          Certificate of Incorporation of the company, and the Memorandum and
          Articles of Association (or the equivalent)
        </label>
        <input
          type="file"
          className="form-control"
          name="certifivateIncorporation"
          required
          onChange={(e) => uploadfiles(e, "certifivateIncorporation")}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="financialAccount">
          A copy of the latest company financial accounts
        </label>
        <input
          type="file"
          className="form-control"
          name="financialAccount"
          required
          onChange={(e) => uploadfiles(e, "financialAccount")}
        />
      </div>
      <div className="form-group mb-3">
        <label htmlFor="DeclarationTrust">
          If shares are held in nominee then we will require a copy of the
          relevant Declaration of Trust/Trsut Documents confirming that the said
          shares are being held in trust for the Ultimate Beneficial Owner
        </label>
        <input
          type="file"
          className="form-control"
          name="DeclarationTrust"
          required
          onChange={(e) => uploadfiles(e, "DeclarationTrust")}
        />
      </div>
      <button className="btn btn-light" type="submit">
        submit
      </button>
    </form>
  );
};

export default Indvidual;
