import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";
import Logo from "../assets/img/Right SideText.svg";

const NavBar = () => {
  const [scrollVal, setScrollVal] = useState(0);
  useEffect(() => {
    window.onscroll = () => {
      setScrollVal(window.pageYOffset);
    };
  });
  return (
    <Navbar
      bg={scrollVal < window.innerHeight ? "tranparent" : "charcol"}
      expand="lg"
      variant="dark"
      className="HomeNavBar w-100"
    >
      <div className="container">
        <Navbar.Brand href="/">
          <img src={Logo} alt="Logo" height="60px" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="mx-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="#action1">Products</Nav.Link>
            <Nav.Link href="#action2">Newsroom</Nav.Link>
            <Nav.Link href="#action2">Company</Nav.Link>
            <Nav.Link href="#action2">Store</Nav.Link>
            <Nav.Link href="#action2">Support</Nav.Link>
          </Nav>
          <Navbar.Brand href="/" style={{ visibility: "hidden" }}>
            <img src={Logo} alt="Logo" height="60px" />
          </Navbar.Brand>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default NavBar;
